<template>
  <div class="card filemanager-sidebar me-md-2 partner-side-menu" ref="parentList" style="background-color: #808b88;">
    <div class="card-body">
      <div class="d-flex flex-column">
        <div>
          <ul class="list-unstyled categories-list">
            <li>
              <div class="custom-accordion">
                <div class="card border-0 shadow-none mb-0">
                  <ul class="list-unstyled mb-0" style="background-color: #808b88;">
                    <li>
                      <a href="javascript: void(0)" @click="getPartners(null)" class="d-flex align-items-center">
                        <span class="fa fa-arrow-right me-1" v-if="selectedPartner === null" style="color: #ffffff;"> </span>
                        <partner-badge :partner="$store.getters['auth/user']"></partner-badge>
                        <span class="me-auto" style="color: #ffffff;">{{ $store.getters['auth/user'].username }}</span>
                        <span class="badge badge-pill badge-soft-danger me-1" v-b-tooltip.hover data-placement="top" title="파트너수" v-if="partnerCount > 0" style="color: #ffffff;">{{ partnerCount }}</span>
                        <span class="badge badge-pill badge-soft-warning" v-b-tooltip.hover data-placement="top" title="회원수" v-if="userCount > 0">{{ userCount }}</span>
                      </a>
                      <ul class="list-unstyled mb-0">
                        <li v-for="top in partners" :key="'partner-' + top.id">
                          <a href="javascript: void(0)" @click="getPartners(top)" class="d-flex align-items-center">
                            <span class="fa fa-arrow-right me-1" v-if="selectedPartner && selectedPartner.id === top.id" style="color: #ffffff;"></span>
                            <partner-badge :partner="top"></partner-badge>
                            <span class="me-auto" style="color: #ffffff;">{{ top.username }}<template v-if="top.username === 'uniontop'"> (테스트)</template></span>
                            <span class="badge badge-pill badge-soft-danger me-1" v-b-tooltip.hover data-placement="top" title="파트너수" v-if="top.partnerCount > 0" style="color: #ffffff;">{{ top.partnerCount }}</span>
                            <span class="badge badge-pill badge-soft-warning" v-b-tooltip.hover data-placement="top" title="회원수" v-if="top.userCount > 0">{{ top.userCount }}</span>
                          </a>
                          <ul class="list-unstyled mb-0">
                            <li v-for="superA in top.children" :key="'child-' + superA.id">
                              <a href="javascript: void(0)" @click="getPartners(superA)" class="d-flex align-items-center">
                                <span class="fa fa-arrow-right me-1" v-if="selectedPartner && selectedPartner.id === superA.id" style="color: #ffffff;"></span>
                                <partner-badge :partner="superA"></partner-badge>
                                <span class="me-auto" style="color: #ffffff;">{{ superA.username }}</span>
                                <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" title="파트너수" v-if="superA.partnerCount > 0" style="color: #ffffff;">{{ superA.partnerCount }}</span>
                                <span class="badge badge-pill badge-soft-warning" v-b-tooltip.hover data-placement="top" v-if="superA.userCount > 0">{{ superA.userCount }}</span>
                              </a>
                              <ul class="list-unstyled mb-0">
                                <li v-for="master in superA.children" :key="'child-' + master.id">
                                  <a href="javascript: void(0)" @click="getPartners(master)" class="d-flex align-items-center">
                                    <span class="fa fa-arrow-right me-1" v-if="selectedPartner && selectedPartner.id === master.id" style="color: #ffffff;"></span>
                                    <partner-badge :partner="master"></partner-badge>
                                    <span class="me-auto" style="color: #ffffff;">{{ master.username }}</span>
                                    <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" title="파트너수" v-if="master.partnerCount > 0" style="color: #ffffff;">{{ master.partnerCount }}</span>
                                    <span class="badge badge-pill badge-soft-warning" v-b-tooltip.hover data-placement="top" v-if="master.userCount > 0">{{ master.userCount }}</span>
                                  </a>
                                  <ul class="list-unstyled mb-0">
                                    <li v-for="agent in master.children" :key="'child-' + agent.id">
                                      <a href="javascript: void(0)" @click="getPartners(agent)" class="d-flex align-items-center">
                                        <span class="fa fa-arrow-right me-1" v-if="selectedPartner && selectedPartner.id === agent.id" style="color: #ffffff;"></span>
                                        <partner-badge :partner="agent"></partner-badge>
                                        <span class="me-auto" style="color: #ffffff;">{{ agent.username }}</span>
                                        <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" title="파트너수" v-if="agent.partnerCount > 0" style="color: #ffffff;">{{ agent.partnerCount }}</span>
                                        <span class="badge badge-pill badge-soft-warning" v-b-tooltip.hover data-placement="top" v-if="agent.userCount > 0">{{ agent.userCount }}</span>
                                      </a>
                                      <ul class="list-unstyled mb-0">
                                        <li v-for="shop in agent.children" :key="'child-' + shop.id">
                                          <a href="javascript: void(0)" @click="getPartners(shop)" class="d-flex align-items-center">
                                            <span class="fa fa-arrow-right me-1" v-if="selectedPartner && selectedPartner.id === shop.id" style="color: #ffffff;"></span>
                                            <partner-badge :partner="shop"></partner-badge>
                                            <span class="me-auto" style="color: #ffffff;">{{ shop.username }}</span>
                                            <span class="badge badge-pill badge-soft-warning" v-b-tooltip.hover data-placement="top" v-if="shop.userCount > 0">{{ shop.userCount }}</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .filemanager-sidebar {
    /*
    min-width: 30px;
    max-width: 30px;
     */
  }
</style>

<script>
import PartnerBadge from '../badge-level'

export default {
  components: {
    PartnerBadge
  },
  props: {
    partner: { type: Object, default: null }
  },
  data: function () {
    return {
      partners: [],
      selectedPartner: null,
      userCount: 0,
      partnerCount: 0
    }
  },
  mounted() {
    this.getMyCount()
  },
  methods: {
    getMyCount: async function() {
      const result = await this.$API.partner.countChildren()
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.userCount = result.userCount
      this.partnerCount = result.partnerCount
    },
    getPartners: async function(parent) {
      if (parent) {
        this.selectedPartner = parent
        this.$emit('setParent', parent.id)
      } else {
        this.selectedPartner = null
        this.$emit('setParent', null)
      }

      this.$emit('searchList')

      const loader = this.$loading.show({ container: this.$refs.parentList })
      try {
        const result = await this.$API.partner.children(parent ? parent.id : null)
        if (result.code !== 0) {
          return alert(result.msg)
        }
        if (parent) {
          for(const key in result.documents) {
            const partner = result.documents[key]
            let find = false
            for(const key1 in parent.children) {
              const child = parent.children[key1]
              if (child.id === partner.id) {
                child.partnerCount = partner.partnerCount
                find = true
                break
              }
            }
            if (find === false) {
              partner.children = []
              parent.children.push(partner)
            }
          }
        } else {
          for(const key in result.documents) {
            const partner = result.documents[key]
            let find = false
            for (const key1 in this.partners) {
              const child = this.partners[key1]
              if (child.id === partner.id) {
                child.partnerCount = partner.partnerCount
                find = true
                break
              }
            }
            if (find === false) {
              partner.children = []
              this.partners.push(partner)
            }
          }
        }
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
}
</script>
