<template>
  <div class="dropdown">
    <input type="text" class="form-control dropdown-toggle" data-bs-toggle="dropdown" v-model="selectedVendorName" @keyup="typeVendorName" placeholder="게임사" />
    <ul class="dropdown-menu">
      <template v-for="cat in categories" :key="'categories-' + cat._id">
        <li class="dropdown-item text-center bg-soft bg-secondary">{{ cat.name }}</li>
        <li v-for="vendor in cat.children" :key="'vendor-' + vendor._id" :class="{'bg-warning': this.selectedVendorIDS.indexOf(vendor._id) > -1}">
          <a class="dropdown-item" href="javascript:void(0)" @click="selectVendor(vendor)">
            {{ vendor.names[$store.getters['auth/user'].language] }}
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
      selectedVendorName: '',
      selectedVendorId: 0,
      selectedVendorIDS: [],
      vendors: [],
      categories: [],
    }
  },
  mounted() {
    this.getVendors().then()
  },
  methods: {
    async getVendors() {
      const result = await this.$API.setting.vendors({sort: {sort: 1}})
      if (result.code !== 0) {
        return alert(result.msg)
      }

      this.vendors = result.documents

      // 카테고리를 뽑느다.
      for(const key in this.vendors) {
        const vendor = this.vendors[key]

        let find = false
        for(const key2 in this.categories) {
          const cat = this.categories[key2]
          if (cat._id === vendor.category._id) {
            find = cat
            break
          }
        }
        if (find === false) {
          const cat = JSON.parse(JSON.stringify(vendor.category))
          cat.children = [vendor]
          this.categories.push(cat)
        } else {
          find.children.push(vendor)
        }
      }
    },
    selectVendor(vendor) {
      this.selectedVendorId = vendor._id
      this.selectedVendorIDS = [vendor._id]
      this.$emit('searchVendors', vendor._id)
      this.selectedVendorName = vendor.names[this.$store.getters['auth/user'].language]
    },
    typeVendorName() {
      this.selectedVendorId = 0
      this.selectedVendorIDS = []
      if (this.selectedVendorName.trim() === '') return
      for(const key in this.categories) {
        const cat = this.categories[key]
        for(const key1 in cat.children) {
          const vendor = cat.children[key1]
          if (vendor.names[this.$store.getters['auth/user'].language].toLowerCase().indexOf(this.selectedVendorName.toLowerCase()) > -1) {
            this.selectedVendorIDS.push(vendor._id)
          }
        }
      }
    }
  },
  watch: {

  }
}
</script>

<style scoped>
.dropdown-menu {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  /* max-height: 360px; */
}
</style>
