import * as crypto from 'crypto'
import * as ShortID from 'shortid'
import URL from 'url'

export function randomString (length) {
  let result = ''
  // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength))
  }
  return result
}

export function validateUrl (u) {
  try {
    let a = new URL.Url()
    a = a.parse(u)

    if(a.protocol !== 'https:' && a.protocol !== 'http:') {
      return false
    }
    if(!a.host) {
      return false
    }
    return a
  } catch (e) {
    console.log(e)
    return false
  }
}

export async function sleep (ms) {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms)
  })
}

export function usecTime () {
  const rgMicrotime = microtime().split(' ')
  let usec = rgMicrotime[0]
  const sec = rgMicrotime[1]

  usec = usec.substr(2, 3)
  return Number(String(sec) + String(usec))
}

export function microtime (useFloat = false) {
  const now = new Date().getTime() / 1000
  const s = parseInt(now, 10)

  return (useFloat) ? now : (Math.round((now - s) * 1000) / 1000) + ' ' + s
}

export function base64Encode (data) {
  const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let o1 = 0
  let o2 = 0
  let o3 = 0
  let h1 = 0
  let h2 = 0
  let h3 = 0
  let h4 = 0
  let bits = 0
  let i = 0

  let ac = 0
  let enc = ''
  const tmpArr = []

  if (!data) {
    return data
  }

  do { // pack three octets into four hexets
    o1 = data.charCodeAt(i++)
    o2 = data.charCodeAt(i++)
    o3 = data.charCodeAt(i++)

    bits = o1 << 16 | o2 << 8 | o3

    h1 = bits >> 18 & 0x3f
    h2 = bits >> 12 & 0x3f
    h3 = bits >> 6 & 0x3f
    h4 = bits & 0x3f

    tmpArr[ac++] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4)
  } while (i < data.length)

  enc = tmpArr.join('')

  var r = data.length % 3

  return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3)
}

// eslint-disable-next-line no-unused-vars
function chr (codePt) {
  if (codePt > 0xFFFF) {
    codePt -= 0x10000
    return String.fromCharCode(0xD800 + (codePt >> 10), 0xDC00 + (codePt & 0x3FF))
  }
  return String.fromCharCode(codePt)
}

function _S4 () {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export function guid () {
  return (_S4() + _S4() + '-' + _S4() + '-' + _S4() + '-' + _S4() + '-' + _S4() + _S4() + _S4())
}

export function random (count) {
  return Math.floor(Math.random() * count)
}

export function md5 (str) {
  const checksum = crypto.createHash('md5')
  checksum.update(str)
  return checksum.digest('hex')
}

export function sha1 (str) {
  const checksum = crypto.createHash('sha1')
  checksum.update(str)
  return checksum.digest('hex')
}

export function uniqueID () {
  return ShortID.generate()
}

export function stringReplace (str, src, dest) {
  const reg = new RegExp(src, 'gi')
  return str.replace(reg, dest)
}

export function maskString (str) {
  const firstPosition = parseInt(str.length / 2)
  const destStr = str.slice(firstPosition)
  const reg = new RegExp(destStr, 'gi')

  let prefix = ''
  for (let i = 0; i < destStr.length; i++) {
    prefix = prefix + '*'
  }

  const result = str.replace(reg, prefix)
  return result
}

export function asciiToHex (str) {
  var arr = []
  for (var i = 0, l = str.length; i < l; i++) {
    var hex = Number(str.charCodeAt(i)).toString(16)
    arr.push(hex)
  }
  return arr.join('')
}

export function hexToAscii (hexx) {
  const hex = hexx.toString()
  let str = ''
  for (var i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  }
  return str
}

export function leadingZeros (n, digits = 2) {
  let zero = ''
  let i
  n = n.toString()

  if (n.length < digits) {
    for (i = 0; i < digits - n.length; i++) {
      zero += '0'
    }
  }
  return zero + n
}
