<script>
import DatePicker from "@vuepic/vue-datepicker"
import Deposit from "../bank/deposit"
import Withdraw from '../bank/withdraw'
import PartnerBadge from '../badge-level'
import PartnerTreeMenu from '@/components/partner/tree-menu'
import VPagination from '@hennge/vue3-pagination'
import '/src/assets/styles/pagination.default.css'

export default {
  components: {
    DatePicker,
    Deposit,
    Withdraw,
    PartnerBadge,
    PartnerTreeMenu,
    VPagination
  },
  props: {
    searchByParent: Boolean,
    parent: Object
  },
  data() {
    return {
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        beginDate: '',
        endDate: '',
        state: '',
        parent: null
      },
      sort: { sort: 1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
    }
  },
  created() {
    this.state = this.$route.params.state
  },
  mounted() {
    /*
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 365)
    let endDate = new Date()

    this.search.beginDate = beginDate
    this.search.endDate = endDate
     */

    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.search.parent = this.parent ? this.parent.id : null
    if (this.searchByParent === true) {
      this.searchList().then()
    } else {
      this.$refs.partnerTreeMenu.getPartners().then()
    }
  },
  methods: {
    setParent: function(id) {
      this.search.parent = id
    },
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    searchByPage: function() {
      this.searchList().then()
    },
    searchList: async function() {
      if (this.searchByParent === true && !this.parent) return
      const loader = this.$loading.show({ container: this.$refs.userList })
      try {
        localStorage.setItem('limit', this.limit)

        this.search.beginDate = new Date(this.search.beginDate)
        this.search.endDate = new Date(this.search.endDate)

        const params = {
          search: {parent: this.search.parent},
          sdate: this.search.beginDate.formatUTC('YYYY-MM-DD HH:mm:ss'),
          edate: this.search.endDate.formatUTC('YYYY-MM-DD HH:mm:ss'),
          sort: this.sort,
          limit: this.limit,
          page: this.page,
        }
        params.search[this.search.searchType] = this.search.searchText
        params.search.state = this.state

        const result = await this.$API.user.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []

        this.list = result.documents
        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount ? result.totalPageCount : 1
        // this.page = result.page
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openDeposit: function(user) {
      this.$refs['modal-deposit'].open(user)
    },
    openWithdraw: function(user) {
      this.$refs['modal-withdraw'].open(user)
    },
    deleteChildren: async function(user) {
      if (!confirm(`${user.username}을 완전 삭제하시겠습니까?`)) {
        return
      }

      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.user.delete(user.id)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.searchList()
      } catch(e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    }
  },
  watch: {
    user: function(val) {
      if (val) {
        this.searchAll()
      }
    }
  }
}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList">
      <div class="row">
        <div class="form-group col-md-1 mb-2">
        </div>
        <div class="form-group col-md-3 mb-2">
          <DatePicker
              v-model="search.beginDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('검색') +  $t('시작') +  $t('일시')"
          ></DatePicker>
          <!--
          <DatePicker
              v-model="search.beginDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              :placeholder="$t('검색') +  $t('시작') +  $t('일시')"
              utc
          ></DatePicker>
          -->
        </div>

        <div class="form-group col-md-3 mb-2">
          <DatePicker
              v-model="search.endDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('검색') +  $t('종료') +  $t('일시')"
          ></datepicker>
          <!--
          <DatePicker
              v-model="search.endDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              :placeholder="$t('검색') +  $t('종료') +  $t('일시')"
              utc
          ></datepicker>
          -->
        </div>

        <div class="form-group col-md-1 mb-2">
          <select class="form-select" v-model="search.searchType">
            <option value="username">{{ $t('아이디') }}</option>
            <option value="siteUsername">{{ $t('업체 아이디') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>

        <div class="col-md-1 mb-1">
          <select class="form-select" v-model="limit" @change="searchList">
            <option :value="10">{{ $t('10개') }}</option>
            <option :value="25">{{ $t('25개') }}</option>
            <option :value="50">{{ $t('50개') }}</option>
            <option :value="100">{{ $t('100개') }}</option>
          </select>
        </div>

        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-primary w-100">{{ $t('검색') }}</button>
        </div>
      </div>

    </form>

    <hr class="mt-0 mb-3" />

    <div class="d-xl-flex">

      <div class="w-100">
        <div class="d-md-flex">

          <!-- 상위 목록 //-->
          <partner-tree-menu :partner="$store.getters['auth/user']" @setParent="setParent" @searchList="searchList" ref="partnerTreeMenu" v-if="searchByParent !== true"></partner-tree-menu>

          <!-- 목록 //-->
          <div class="table-responsive mb-0 rounded-3 w-100" ref="userList">
            <div class="w-100">
              <div class="card">
                <div class="card-body">

                    <table class="table align-middle table-nowrap sticky-table">
                      <thead class="table-light">
                      <tr>
                        <th class="align-middle">
                          <a href="javascript:void(0)" @click="setSort('createdAt')">#</a>&nbsp;
                          <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                        </th>
                        <th class="align-middle">{{ $t('상위정보') }}</th>
                        <th class="align-middle">{{ $t('회원아이디') }}</th>
                        <th class="align-middle">{{ $t('업체아이디') }}</th>
                        <th class="align-middle">{{ $t('닉네임') }}</th>
                        <th class="align-middle">
                          <a href="javascript:void(0)" @click="setSort('cash')">{{ $t('보유머니') }}</a>&nbsp;
                          <template v-if="sort.field === 'cash'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                        </th>
                        <th class="align-middle">
                          <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('가입시간') }}</a>&nbsp;
                          <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="record in list" :key="record.id">
                        <td>
                          <div class="dropdown">
                            <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                              <span class="mdi mdi-menu"></span>
                            </button>
                            <ul class="dropdown-menu">
                              <li><h5 class="dropdown-header text-center">{{ record.id }} ({{ record.username }})</h5></li>
                              <li class="dropdown-divider"></li>
                              <li><a class="dropdown-item" href="javascript:void(0)" @click="openDeposit(record)">{{ $t('머니충전') }}</a></li>
                              <li><a class="dropdown-item" href="javascript:void(0)" @click="openWithdraw(record)">{{ $t('머니환전') }}</a></li>
                              <li class="dropdown-divider"></li>
                              <li><router-link :to="'/user/detail/' + record.id" class="dropdown-item" target="_blank">{{ $t('자세히') }}</router-link></li>
                              <template v-if="$store.getters['auth/user'].type === 'admin'">
                                <li class="dropdown-divider"></li>
                                <li><a href="javascript: void(0)" class="dropdown-item text-danger" @click="deleteChildren(record)">{{ $t('완전 삭제') }}</a></li>
                              </template>
                            </ul>
                          </div>
                        </td>
                        <td>


                          <router-link :to="'/partner/detail/' + record.top.id" target="_blank" v-if="record.top &&  ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.top" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.super.id" target="_blank" v-if="record.super && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.super" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.master.id" target="_blank" v-if="record.master && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.master" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.agent.id" target="_blank" v-if="record.agent && ($store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.agent" :show-name="true"></partner-badge></router-link>
                          <router-link :to="'/partner/detail/' + record.shop.id" target="_blank" v-if="record.shop"><partner-badge :partner="record.shop" :show-name="true"></partner-badge></router-link>

                        </td>
                        <td>
                          <span class="ms-1"><router-link :to="'/user/detail/' + record.id" target="_blank">{{ record.username }}</router-link></span>
                        </td>
                          <td>
                              <span class="ms-1">{{ record.siteUsername }}</span>
                          </td>
                          <td>
                              <span class="ms-1">{{ record.nickname }}</span>
                          </td>
                        <td class="text-end">{{ record.cash.floatVal(2).formatThousands() }}</td>
                        <td class="small">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
                      </tr>
                      </tbody>
                    </table>

                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end w-100 -->
        </div>
      </div>
    </div>

    <v-pagination
        class="justify-content-center mb-2"
        v-model="page"
        :pages=totalPageCount
        :range-size="2"
        active-color="#DCEDFF"
        @update:modelValue="searchByPage"
    />
    <Deposit ref="modal-deposit" @refresh="searchList" />
    <Withdraw ref="modal-withdraw" @refresh="searchList" />
  </div>
</template>
