<script>
import DatePicker from "@vuepic/vue-datepicker"
import PartnerTreeMenu from '@/components/partner/tree-menu'

export default {
  props: {
    user: Object,
    searchByUser: Boolean,
    searchByParent: Boolean,
    hideTree:Boolean
  },
  components: {
    DatePicker,
    PartnerTreeMenu
  },
  data() {
    return {
      search: {
        beginDate: null,
        endDate: null,
        includeBelow: false
      },
      title: '',
      list: null,
      statData: null
    }
  },
  mounted () {
    let beginDate = new Date()
    beginDate = new Date(beginDate.getFullYear(), beginDate.getMonth(), beginDate.getDate(), 0)

    let endDate = new Date()
    endDate.setDate(endDate.getDate() + 1)
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0)

    // 일별
    this.search.beginDate = beginDate
    this.search.endDate = endDate

    if (this.searchByUser === false && this.searchByParent === false && this.hideTree !== true) {
      this.parentId = this.$store.getters['auth/user'].id
      this.$refs.partnerTreeMenu.getPartners().then()
    } else {
      this.searchList()
    }
  },
  methods: {
    setParent: function(id) {
      this.parentId = id
    },
    searchList: async function() {
      if (this.searchByUser === true && !this.user) return
      const loader = this.$loading.show({ container: this.$refs.listContainer})
      try {
        const result = await this.$API.statistic.getStatBetDateByVendor(new Date(this.search.beginDate).toISOString(), new Date(this.search.endDate).toISOString(), { userId: this.user.id, parentId: this.parentId, includeBelow: this.search.includeBelow })
        if (result.code !== 0) {
          return alert(result.msg)
        }
        this.list = result.documents
        this.statData = result.statData

      } finally {
        loader.hide()
      }
    },
  },
  watch: {
    user: function(val) {
      if (val) {
        this.searchList()
      }
    }
  }
}
</script>

<template>
  <div ref="listContainer">

    <form class="header-search" @submit.prevent="searchList">
      <!-- 페이징 및 검색 //-->
      <div class="row">
        <div class="col-md-3">

        </div>
        <div class="col-md-2">
          <b-form-checkbox v-model="search.includeBelow" switch size="lg"  v-if="searchByUser === false && searchByParent === false" @change="searchList">
            {{ $t('하부포함') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-3 mb-2">
          <div class="form-group">
            <DatePicker
                v-model="search.beginDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('검색') +  $t('시작') +  $t('일시')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-3 mb-2">
          <div class="form-group">
            <DatePicker
                v-model="search.endDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('검색') +  $t('종료') +  $t('일시')"
            ></DatePicker>
          </div>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-primary w-100">{{ $t('검색') }}</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <div class="w-100">
      <div class="d-md-flex">

        <!-- 상위 목록 //-->
        <partner-tree-menu :partner="$store.getters['auth/user']" @setParent="setParent" @searchList="searchList" ref="partnerTreeMenu" v-if="(searchByUser === false && searchByParent === false) && hideTree !== true"></partner-tree-menu>

        <!-- 목록 //-->
        <div class="table-responsive mb-0 rounded-3 w-100" style="overflow: auto;">
          <div class="w-100">
            <div class="card">
              <div class="card-body">
                <table class="table align-middle table-sm table-bordered table-nowrap sticky-table">
                  <thead class="table-light">
                  <tr>
                    <th>{{ $t('형식') }}</th>
                    <th>{{ $t('게임사') }}</th>
                    <th>{{ $t('배팅') }}</th>
                    <th>{{ $t('승') }}</th>
                    <th>{{ $t('무') }}</th>
                    <th>{{ $t('취소') }}</th>
                    <th>{{ $t('유효배팅') }}</th>
                    <th>{{ $t('Win/Lose') }}</th>
                    <th>{{ $t('환수율') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(category, index) in list" :key="'stat-' + index">
                    <tr v-for="(vendor, vendorIndex) in category.vendors" :key="'vendor-' + vendor._id">
                      <td :rowspan="category.vendors.length" v-if="vendorIndex === 0">{{ category.name }}</td>
                      <td>{{ vendor.names[$store.getters['auth/user'].language] }}</td>
                      <td class="text-end">{{ vendor.statData.betSum.floatVal(0).formatThousands() }}<!-- ({{ vendor.statData.betCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ vendor.statData.winSum.floatVal(0).formatThousands() }}<!--  ({{ vendor.statData.winCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ vendor.statData.drawSum.floatVal(0).formatThousands() }}<!--  ({{ vendor.statData.drawCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ vendor.statData.cancelSum.floatVal(0).formatThousands() }}<!--  ({{ vendor.statData.cancelCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ (vendor.statData.betSum - vendor.statData.drawSum - vendor.statData.cancelSum).floatVal(0).formatThousands() }}<!-- ({{ (vendor.statData.betCount - vendor.statData.drawCount - vendor.statData.cancelCount).formatThousands() }})//--></td>
                      <td class="text-end">{{ (vendor.statData.betSum - vendor.statData.drawSum - vendor.statData.cancelSum - vendor.statData.winSum).floatVal(0).formatThousands() }}<!-- ({{ (vendor.statData.betCount - vendor.statData.drawCount - vendor.statData.cancelCount - vendor.statData.winCount).formatThousands() }})//--></td>
                      <td>{{ vendor.statData.rtp }}%</td>
                    </tr>
                    <tr class="table-light" v-if="category.vendors.length > 0">
                      <td colspan="2">합계</td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.betSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.betCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.winSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.winCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.drawSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.drawCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.cancelSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.cancelCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.validBetSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.validBetCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.winloseSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.winloseCount.formatThousands() }}) //--></td>
                      <td style="font-weight: bold;">{{ category.statData.rtp }}%</td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="8"></td>
                  </tr>
                  </tbody>
                  <tfoot class="table-dark">
                  <tr v-if="statData">
                    <td colspan="2">합계</td>
                    <td class="text-end">{{ statData.betSum.floatVal(0).formatThousands() }}<!-- ({{ statData.betCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.winSum.floatVal(0).formatThousands() }}<!-- ({{ statData.winCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.drawSum.floatVal(0).formatThousands() }}<!-- ({{ statData.drawCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.cancelSum.floatVal(0).formatThousands() }}<!-- ({{ statData.cancelCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.validBetSum.floatVal(0).formatThousands() }}<!-- ({{ statData.validBetCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.winloseSum.floatVal(0).formatThousands() }}<!-- ({{ statData.winloseCount.formatThousands() }})//--></td>
                    <td>{{ statData.rtp }}%</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
