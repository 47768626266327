<script>
import DatePicker from "@vuepic/vue-datepicker"
import BadgeLevel from "@/components/badge-level"

export default {
  props: {
    user: Object,
    searchByUser: Boolean,
  },
  components: {
    DatePicker,
    BadgeLevel
  },
  data() {
    return {
      search: {
        searchType: 'username',
        searchText: '',
        method: '',
        category: '',
        type: '',
        ipAddress: '',
        beginDate: '',
        endDate: ''
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
    }
  },
  mounted () {
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(0)
    beginDate.setMinutes(0)
    beginDate.setSeconds(0)

    // let endDate = new Date()

    this.search.beginDate = beginDate
    // this.search.endDate = endDate

    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    goFirst() {
      this.page = 1
    },
    goPre() {
      if (this.page === 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
    },
    goNext() {
      this.page += 1
    },
    searchByPage: function(page) {
      this.page = page
    },
    searchList: async function() {
      if (this.searchByUser === true && !this.user) return false

      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        localStorage.setItem('limit', this.limit)
        const params = {
          search: { method: this.search.method, category: this.search.category, type: this.search.action, ipAddress: this.search.ipAddress, user: this.user ? this.user.id : null },
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText

        const result = await this.$API.log.activity(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = result.documents

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  },
  watch: {
    page: function(val) {
      if (val) {
        this.searchList()
      }
    }
  }
}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList">
      <!-- 페이징 및 검색 //-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.beginDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('검색') +  $t('시작') +  $t('일시')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.endDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('검색') +  $t('종료') +  $t('일시')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="search.method" v-if="!searchByUser">
            <option value="">{{ $t('구분') }}</option>
            <option value="site">site</option>
            <option value="game">game</option>
          </select>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="search.category" v-if="!searchByUser">
            <option value="">{{ $t('형식') }}</option>
            <option value="company">company</option>
            <option value="admin">admin</option>
            <option value="partner">partner</option>
            <option value="partnerVendor">partnerVendor</option>
            <option value="game">game</option>
            <option value="gameCategory">gameCategory</option>
          </select>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="search.type" v-if="!searchByUser">
            <option value="">{{ $t('액션') }}</option>
            <option value="create">create-생성</option>
            <option value="login">login-접속</option>
            <option value="run">run-실행</option>
            <option value="deposit">deposit-입금</option>
            <option value="withdraw">withdraw-출금</option>
            <option value="update">update-수정</option>
            <option value="modify">modify-변경</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <input type="text" class="form-control" v-model="search.ipAddress" :placeholder="$t('아이피')" />
        </div>
        <div class="form-group col-md-2 mb-2">
          <select class="form-select" v-model="search.searchType" v-if="!searchByUser">
            <option value="username">{{ $t('아이디') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" v-if="!searchByUser" />
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="limit" @change="searchList">
            <option :value="10">{{ $t('10개') }}</option>
            <option :value="25">{{ $t('25개') }}</option>
            <option :value="50">{{ $t('50개') }}</option>
            <option :value="100">{{ $t('100개') }}</option>
          </select>
        </div>
        <div class="col-md-3 mb-2">
          <div class="form-group">
            <div class="input-group mb-2">
              <button type="button" class="btn btn-outline-success" @click="goFirst">{{ $t('처음') }}</button>
              <button type="button" class="btn btn-outline-primary" @click="goPre">{{ $t('이전') }}</button>
              <input type="number" class="form-control" min="1" v-model="page" />
              <button type="button" class="btn btn-outline-info" @click="goNext">{{ $t('다음') }}</button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-primary w-100">{{ $t('검색') }}</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <div id="table-wrapper" class="table-responsive mb-0 rounded-3" style="overflow: auto">
      <table class="table align-middle table-sm table-bordered table-nowrap sticky-table">
        <thead class="table-light">
        <tr>
          <th>{{ $t('회원정보') }}</th>
          <th>{{ $t('대상') }}</th>
          <th>{{ $t('상위정보') }}</th>
          <th>{{ $t('구분') }}</th>
          <th>{{ $t('형식') }}</th>
          <th>{{ $t('액션') }}</th>
          <th v-if="$store.getters['auth/user'].type === 'admin'">{{ $t('데이터') }}</th>
          <th>{{ $t('메세지') }}</th>
          <th>{{ $t('결과') }}</th>
          <th>{{ $t('아이피') }}</th>
          <th>
            <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('생성일') }}</a>&nbsp;
            <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="record in list" :key="record.id">
          <td>
            <template v-if="record.executor">
              <badge-level :partner="record.executor"></badge-level>
              <span class="ms-1" v-if="record.executor.type === 'admin'">{{ record.executor.username }} ({{ record.executor.nickname }})</span>
              <span class="ms-1"><router-link :to="'/user/detail/' + record.executor.id" target="_blank" v-if="record.executor.type === 'user'">{{ record.executor.username }} ({{ record.executor.siteUsername }})</router-link></span>
              <span class="ms-1"><router-link :to="'/partner/detail/' + record.executor.id" target="_blank" v-if="record.executor.type === 'partner'">{{ record.executor.username }} ({{ record.executor.nickname }})</router-link></span>
              <!--
              <br />
              <span v-if="record.groupKey">{{ record.groupKey }} - </span> {{ record.siteUsername }}
              //-->
            </template>
          </td>
          <td>
            <template v-if="record.user">
              <badge-level :partner="record.user"></badge-level>
              <span class="ms-1" v-if="record.user.type === 'admin'">{{ record.user.username }} ({{ record.user.siteUsername }})</span>
              <span class="ms-1"><router-link :to="'/user/detail/' + record.user.id" target="_blank" v-if="record.user.type === 'user'">{{ record.user.username }} ({{ record.user.siteUsername }})</router-link></span>
              <span class="ms-1"><router-link :to="'/partner/detail/' + record.user.id" target="_blank" v-if="record.user.type === 'partner'">{{ record.user.username }} ({{ record.user.nickname }})</router-link></span>
              <!--
              <br />
              <span v-if="record.groupKey">{{ record.groupKey }} - </span> {{ record.siteUsername }}
              //-->
            </template>
          </td>
          <td>
            <template v-if="record.parent">
              <span class="ms-1" v-if="record.parent"><router-link :to="'/partner/detail/' + record.parent.id" target="_blank" v-if="record.parent && record.parent.id !== $store.getters['auth/user'].id">{{ record.parent.username }}</router-link></span>
            </template>
          </td>
          <td>{{ record.method }}</td>
          <td>{{ record.category }}</td>
          <td>{{ record.type }}</td>
          <td class="text-start" v-if="$store.getters['auth/user'].type === 'admin'">
            <pre class="rounded-lg" v-highlightjs><code style="max-height: 200px; overflow-y: auto; max-width: 500px;" class="javascript rounded" v-html="JSON.stringify(record.data, null, ' ')"></code></pre>
          </td>
          <td class="text-start">{{ record.message }}</td>
          <td>
            <span class="badge bg-primary" v-if="record.result===true">성공</span>
            <span class="badge bg-danger" v-if="record.result!==true">실패</span>
          </td>
          <td>{{ record.ipAddress }}</td>
          <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
