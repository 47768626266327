<template>
  <span class="badge bg-primary" v-if="record.category === 'cash'"><i class="fa fa-piggy-bank"></i> 캐쉬변동</span>
  <span class="badge bg-success" v-if="record.category === 'money'"><i class="fa fa-piggy-bank"></i> 캐쉬변동</span>
  <span class="badge bg-success" v-if="record.category === 'turn'"><i class="fa fa-gamepad"></i> 게임</span>
</template>

<script>
export default {
  props: {
    record: { type: Object }
  },
  methods: {

  },
  computed: {

  }
}
</script>
