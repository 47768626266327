<script>
import detailSettingApi from './detail-setting-api'
import detailSettingVendors from './detail-setting-vendors'
import detailSettingGames from './detail-setting-games'
import detailSettingSeamlessTest from './detail-setting-seamless-test'

export default {
  props: {
    user: Object
  },
  components: {
    detailSettingApi,
    detailSettingVendors,
    detailSettingGames,
    detailSettingSeamlessTest
  },
  data() {
    return {
      content: 'detailSettingApi'
    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div>
    <ul class="nav nav-tabs mb-2">
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailSettingApi'}" href="#" @click="content='detailSettingApi'">API {{ $t('관리')}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailSettingVendors'}" href="#" @click="content='detailSettingVendors'">{{ $t('게임사 관리') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailSettingGames'}" href="#" @click="content='detailSettingGames'">{{ $t('게임 관리') }}</a>
      </li>
      <li class="nav-item" v-if="$store.getters['auth/user'].type === 'admin' && user && user.useSeamless === 1">
        <a class="nav-link" :class="{active: content==='detailSettingSeamlessTest'}" href="#" @click="content='detailSettingSeamlessTest'">{{ $t('테스트') }}</a>
      </li>
    </ul>
    <div class="p-2">
      <component :is="content" :user="user"></component>
    </div>
  </div>
</template>
